import React from "react";
import Page from "../../common/layout/Page";
import MerlinHelp from "./MerlinHelp";
import { DOC_LINKS } from "./documentationLinks";

function Documentation() {
  return (
    <Page
      title="Guides d'utilisation"
      subTitle="Retrouvez ici des guides pour vous aider à utiliser les fonctionnalités de votre backoffice."
      titleMargin="mb-1"
    >
      <MerlinHelp linkKey="guides-utilisation" />
      <ul className="list list-unstyled mt-3">
        {DOC_LINKS.filter((l) => l.url !== "#" && !l.unlisted).map((link) => (
          <li className="list-item py-2">
            <a
              target="_blank"
              rel="noreferrer"
              className="h4 p-0"
              style={{ textTransform: "none" }}
              href={link.url}
            >
              <i className="fa fa-link" /> {link.title}
            </a>
          </li>
        ))}
      </ul>
    </Page>
  );
}

export default Documentation;
