import React, { useEffect } from "react";
import styled from "styled-components";
import useCompanyItem from "../../useCompanyItem";

export const CloseCompanyBottomPanelButton = () => {
  const { actions, bottomPanelContent } = useCompanyItem();
  return bottomPanelContent ? (
    <StyledArrowButton
      className="fabArrow d-center shadow-sm bg-white  border cursor-pointer"
      onClick={actions.closeBottomPanel}
    >
      <i className="fa fa-times" />
    </StyledArrowButton>
  ) : null;
};

const CompanyBottomPanel = ({ children }) => {
  useEffect(() => {
    // Add class to body to prevent scrolling
    document.body.classList.add("no-scroll");

    // Cleanup function to remove class when component unmounts
    return () => {
      document.body.classList.remove("no-scroll");
    };
  }, []);

  return (
    <StyledCompanyBottomPanel className="companyBottomPanel">
      <CloseCompanyBottomPanelButton />
      {children}
    </StyledCompanyBottomPanel>
  );
};

const StyledCompanyBottomPanel = styled.div`
  padding: 30px 15px;
  position: fixed;
  box-shadow: inset 0px 6px 6px -10px #000000;
  max-height: calc(100%);
  overflow-y: scroll;
  scroll-behavior: smooth;
  background: #efefef;

  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 99999999;
`;

const StyledArrowButton = styled.div`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  position: absolute;
  right: 0px;
  top: 40px;
  transform: translateX(-50%) translateY(-20px);
  z-index: 9;
  & i {
    transition: 300ms;
  }

  &:hover {
    i {
      transform: rotate(-180deg);
    }
  }
`;

export default CompanyBottomPanel;
