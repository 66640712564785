export const DOC_LINKS = [
  {
    key: "guides-utilisation",
    url: "#",
    title: "Découvrez les fonctionnalités de votre backoffice",
    description:
      "Cette liste de guides vous permettra d'apprendre à utiliser votre backoffice et ses fonctionnalités.",
  },
  {
    key: "leads-internes",
    url: "/documentation",
    title: "Comment utiliser les leads internes ?",
    unlisted: true,
    description:
      "Je vous ai concocté des tutoriels pour vous aider à utiliser les leads internes, cliquez ici pour les consulter.",
  },
  {
    key: "leads-internes-ac-pj",
    url: "https://app.tango.us/app/workflow/Leads-internes-11227acc7b8e49cbad58d4cb38335fc3",
    title:
      "Comment utiliser les leads internes - Protection Juridique & Assurance Construction",
    description:
      "Je vous ai concocté des tutoriels pour vous aider à utiliser les leads internes, cliquez ici pour les consulter.",
  },
  {
    key: "leads-internes-cyber",
    url: "https://app.tango.us/app/workflow/Leads-internes---Assurance-Cyber-------2d8c077855234731b129588bf4b37ea2",
    title: "Comment utiliser les leads internes - Assurance Cyber",
    description:
      "Je vous ai concocté un petit guide pour vous aider à utiliser les leads internes, cliquez ici pour le consulter.",
  },
];

export const getDocByKey = (key) => {
  return DOC_LINKS.find((link) => link.key === key);
};
