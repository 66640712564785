import React, { useState } from "react";
import tools, { PAGES } from "../../../../../helpers/tools";
import Control from "../../../../common/Controls/Control";

const WebPageSelector = ({ onChangeSelectedPage = () => {} }) => {
  const [selectedPage, setSelectedPage] = useState("FORM");

  const onChange = (e) => {
    setSelectedPage(e.target.value);
    const pageLink = tools.findIn(PAGES, e.target.value, "id").link;
    onChangeSelectedPage(pageLink);
  };

  return (
    <Control
      label="Choisissez vers quelle page doit pointer votre lien d'affiliation :"
      type="select"
      name="selectedPage"
      datas={PAGES}
      dataIndex="id"
      dataLabel="name"
      change={onChange}
      value={selectedPage}
      noBg
      noPaddingOnFormGroup
      noFormGroup
      margin="mb-0"
    />
  );
};

export default WebPageSelector;
