import { useAtom } from "jotai";
import { DateTime } from "luxon";
import { useContext, useState } from "react";
import { leadsFiltersAtom } from "../atoms/leadListAtoms";
import { LeadContext } from "../LeadList/LeadContext";
import useLeads, { filtersByProduct, LeadPageContext } from "../useLeads";

const useLeadFilters = () => {
  const { productCategory } = useContext(LeadPageContext);
  const [leadsFilters, setLeadsFilters] = useAtom(leadsFiltersAtom);
  const { isListInit, isSearching, actions } = useLeads();
  const [showExpiredManagers, setShowExpiredManagers] = useState(false);

  const resetLeadsFilters = () => {
    setLeadsFilters(filtersByProduct[productCategory]);
  };

  const updatePage = (page) => {
    const newFilters = {
      ...leadsFilters,
      page,
    };
    actions.getLeads(newFilters);
    setLeadsFilters(newFilters);
  };

  const updateFilters = (e) => {
    const { name, value } = e.target;
    setLeadsFilters({
      ...leadsFilters,
      filters: {
        ...leadsFilters.filters,
        [name]: value,
      },
    });
  };

  const updateOneMonth = () => {
    const today = DateTime.now().toISO();
    const minusOneMonth = DateTime.now()
      .minus({ days: 30 })
      .endOf("day")
      .toISO();
    setLeadsFilters({
      ...leadsFilters,
      filters: {
        ...leadsFilters.filters,
        beginAt: minusOneMonth,
        endAt: today,
      },
    });
  };
  const toggleShowExpiredManagers = () => {
    setShowExpiredManagers(!showExpiredManagers);
  };

  return {
    leadsFilters,
    setLeadsFilters,
    showExpiredManagers,
    actions: {
      resetLeadsFilters,
      updateFilters,
      updatePage,
      updateOneMonth,
      toggleShowExpiredManagers,
    },
  };
};

export default useLeadFilters;
