import { Provider } from "jotai";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import styled from "styled-components";
import useManagers from "../../../../hooks/useManagers/useManagers";
import useRole from "../../../../hooks/useRole/useRole";
import useTasks from "../../../../hooks/useTasks/useTasks";
import Loader from "../../../common/Loader";
import LeadListFilters from "../LeadListFilters/LeadListFilters";
import useLeads from "../useLeads";
import { LeadContext } from "./LeadContext";
import LeadCount from "./LeadCount/LeadCount";
import LeadItem from "./LeadItem/LeadItem";
import LeadListPagination from "./LeadListPagination/LeadListPagination";
import "./leadList.scss";

const queryString = require("query-string");

const LeadList = () => {
  const {
    leads,
    linkedLeads,
    actions,
    leadCount,
    showOnlyLinkedLeads,
    isSearching,
  } = useLeads();
  const { tasksByProduct } = useTasks();
  const { managers } = useManagers(true);
  const [isLoading, setIsLoading] = useState(true);
  const location = useLocation();
  const { myRole } = useRole();

  useEffect(() => {
    if (managers.length) {
      setIsLoading(false);
      const parsed = queryString.parse(location.search, {
        arrayFormat: "bracket",
      });
      let newLeadFilters = null;
      if (Object.values(parsed).length > 0) {
        newLeadFilters = parsed;
      }
      actions.resetAtoms({ newLeadFilters });
      //actions.getLeads();
    }
  }, [managers, location.search]);

  const leadsList = showOnlyLinkedLeads ? linkedLeads : leads;

  return isLoading ? (
    <Loader />
  ) : (
    <StyledLeadList>
      <LeadListFilters />
      {!showOnlyLinkedLeads && (
        <>
          <LeadListPagination leadCount={leadCount} />
          <LeadCount />
          <p className="text-center ">
            Seules les demandes validées sont affichées
          </p>
        </>
      )}
      {isSearching ? (
        <Loader />
      ) : (
        leadsList.map((lead, lk) => (
          <div className="leadListCard">
            <LeadContext.Provider
              key={`lead${lead.id}`}
              value={{
                tasksByProduct: tasksByProduct[lead.productId],
                managers,
                getLinkedLeads: actions.getLinkedLeads,
                showOnlyLinkedLeads,
                myRole,
              }}
            >
              <Provider>
                <LeadItem data={lead} />
              </Provider>
            </LeadContext.Provider>
          </div>
        ))
      )}
      {!showOnlyLinkedLeads && <LeadListPagination leadCount={leadCount} />}
    </StyledLeadList>
  );
};

export const StyledLeadList = styled.div``;

export default LeadList;
