import React from "react";
import Control from "../../../../common/Controls/Control";
import FiltersContainer from "../FiltersContainer/FiltersContainer";
import useChartFilters from "./useChartFilters";

const years = [];
for (var i = new Date().getFullYear(); i >= 2021; i--) {
  years.push({
    id: i,
    name: i,
  });
}
years.reverse();

const ChartFilters = () => {
  const { chartFilters, actions } = useChartFilters();

  return (
    <Control
      label="Année"
      type="btnList"
      value={chartFilters.year}
      change={actions.handleChangeChartFilters}
      datas={years}
      dataIndex="id"
      dataLabel="name"
      name="year"
      btnInline
      margin="m-0"
      noPaddingOnFormGroup
      noBg
      fullWidth
    />
  );
};

export default ChartFilters;
