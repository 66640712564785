import React from "react";
import styled from "styled-components";

const OpenDetailButton = ({
  onClick,
  title,
  className = "",
  dataPriv,
  icon = "fa-eye",
  inColumn = false,
  ...props
}) => {
  return (
    <StyleDetailButton
      data-priv={dataPriv}
      onClick={onClick}
      className={`my-2 my-md-0  cursor-pointer ${className}`}
      {...props}
    >
      <div
        className={` px-2  rounded d-flex align-items-center justify-content-between ${
          inColumn ? "flex-column py-2 bg-transparent" : "py-1 bg-blue"
        }`}
      >
        {inColumn && <i className={`fa fa-2x ${icon}`} />}
        <strong className="text-left">
          <span className="">{title}</span>
        </strong>
        {!inColumn && <i className={`fa ml-2 ${icon}`} />}
      </div>
    </StyleDetailButton>
  );
};

const StyleDetailButton = styled.div`
  color: var(--color-primary);
  &:hover {
    color: var(--color-success);
  }
`;

export default OpenDetailButton;
