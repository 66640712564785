import { Provider } from "jotai";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import styled from "styled-components";
import useInsuranceCompanies from "../../../../hooks/useInsuranceCompanies/useInsuranceCompanies";
import useProducts from "../../../../hooks/useProducts/useProducts";
import useProductTypes from "../../../../hooks/useProductTypes/useProductTypes";
import useRole from "../../../../hooks/useRole/useRole";
import { ClientContext } from "./ClientContext/ClientContext";
import ClientCount from "./ClientCount";
import ClientFilters from "./ClientFilters/ClientFilters";
import ClientItem from "./ClientItem/ClientItem";
import ClientListPagination from "./ClientListPagination/ClientListPagination";
import useClientList from "./useClientList";
import useUserCustomers from "./useUserCustomers";
import Loader from "../../../common/Loader";
import tools from "../../../../helpers/tools";
const queryString = require("query-string");

const ClientList = () => {
  const location = useLocation();
  const { myRole } = useRole();
  const { clients, clientFilters, actions, resultCount, isLoadingClients } =
    useClientList();
  const { userCustomers, actions: userCustomersActions } = useUserCustomers();
  const { products, onGetProducts } = useProducts();
  const { productTypes, onGetProductTypes } = useProductTypes();
  const { insuranceCompanies, onGetInsuranceCompanies } =
    useInsuranceCompanies();
  const [highlightedClient, setHighlightedClient] = useState(null);

  useEffect(() => {
    let parsed = queryString.parse(location.search, { arrayFormat: "bracket" });
    if (myRole && myRole != "PARTNER") {
      console.log(myRole);
      //userCustomersActions.getUserCustomers();
    }

    onGetProducts();
    onGetProductTypes();
    onGetInsuranceCompanies();

    if (Object.keys(parsed).length > 0) {
      setHighlightedClient(parsed.clientTerms || "");
      const newFilters = {
        ...clientFilters,
        user: {
          ...clientFilters.user,
          statutContrat: "",
          ...parsed,
        },
      };
      actions.onClientFiltersChange(newFilters);
      actions.onGetClients({ overrideFilters: newFilters });
    }
  }, []);

  useEffect(() => {
    if (clients.length > 0 && highlightedClient) {
      setTimeout(() => {
        const elements = [
          ...document.getElementsByClassName("info__siret"),
          ...document.getElementsByClassName("info__item"),
        ];
        for (var key in elements) {
          const element = elements[key];

          if (element.dataset.value.includes(highlightedClient)) {
            element.scrollIntoView();
            element.classList.add("highlight");
            break;
          }
        }
      }, 1500);
    }
  }, [clients]);

  return (
    <StyledClientList>
      <ClientFilters />
      <ClientCount />
      {isLoadingClients ? (
        <Loader />
      ) : (
        clients.map((client) => {
          return (
            <ClientContext.Provider
              value={{
                clientActions: actions,
                userCustomers: userCustomers,
                userCustomersActions,
                products,
                productTypes,
                insuranceCompanies,
                client,
              }}
              key={`client${client.id}`}
            >
              <Provider>
                <ClientItem clientData={client} />
              </Provider>
            </ClientContext.Provider>
          );
        })
      )}
      <ClientListPagination clientCount={resultCount} />
    </StyledClientList>
  );
};

const StyledClientList = styled.div``;

export default ClientList;
