export const groups = {
  PARAMETERS: {
    id: "PARAMETERS",
    name: "Paramètres",
    icon: "chevron-down",
  },
  CONTENT: {
    id: "CONTENT",
    name: "Contenu",
    icon: "chevron-down",
  },

  ADVANCED: {
    id: "ADVANCED",
    name: "Avancé",
    icon: "chevron-down",
  },
};
export const sidenavItems = [
  { name: "Accueil", path: "/dashboard", icon: "home" },
  {
    name: "Clients",
    path: "/client-list",
    icon: "users",
    dataPriv: "bo_searchclient",
  },

  {
    name: "Leads entreprise",
    path: "/leads",
    icon: "user-clock",
    dataPriv: "bo_viewusertemp",
  },
  {
    name: "Leads DOMI",
    path: "/leads-domi",
    icon: "user-clock",
    dataPriv: "bo_viewusertempdomi",
  },
  {
    name: "Leads internes",
    path: "/leads-internes",
    icon: "user-clock",
    dataPriv: "bo_viewusertemp",
  },
  {
    name: "Renouvellement PJ",
    path: "pj-renew",
    icon: "chess-rook",
    dataPriv: "bo_viewcontractdocs",
  },
  {
    name: "Simulateur PJ",
    path: "/simulator",
    icon: "calculator",
    dataPriv: "bo_usetarificator",
  },
  {
    name: "Documents",
    path: "/files",
    icon: "file-alt",
    dataPriv: "bo_searchfiles",
  },
  {
    name: "Guides d'utilisation",
    path: "/documentation",
    icon: "book-reader",
  },
  { name: "Ma timeline", path: "/timeline", icon: "heartbeat" },

  //ADVANCED
  {
    name: "Liste des commissions",
    path: "/partner-commissions",
    icon: "handshake",
    dataPriv: "bo_readcommissions",
    group: groups.ADVANCED.id,
  },
  {
    name: "Liste RBE",
    path: "/rbe-list",
    icon: "user-shield",
    dataPriv: "bo_rbeactions",
    group: groups.ADVANCED.id,
  },
  {
    name: "Liste noire",
    path: "/black-list",
    icon: "ban",
    dataPriv: "bo_blacklistactions",
    group: groups.ADVANCED.id,
  },
  {
    name: "SFTP BFC",
    path: "/ftpclient",
    icon: "exchange-alt",
    dataPriv: "bo_crudtestimonials",
    group: groups.ADVANCED.id,
  },
  //GROUPE PARAMETRES
  {
    name: "Utilisateurs BO",
    path: "/users",
    icon: "user",
    dataPriv: "bo_crudusers",
    group: groups.PARAMETERS.id,
  },
  {
    name: "Rôles utilisateurs",
    path: "/roles",
    icon: "fingerprint",
    dataPriv: "bo_updaterolesconfig",
    group: groups.PARAMETERS.id,
  },
  {
    name: "Clients à importer",
    path: "/clients-modulr",
    icon: "user-times",
    dataPriv: "bo_viewparameters",
    group: groups.PARAMETERS.id,
  },
  {
    name: "Catégories de docs",
    path: "/document-categories",
    icon: "folder",
    dataPriv: "bo_crudfilecategory",
    group: groups.PARAMETERS.id,
  },
  {
    name: "Types de docs",
    path: "/file-types",
    icon: "file-excel",
    dataPriv: "bo_crudfiletype",
    group: groups.PARAMETERS.id,
  },
  {
    name: "Imports Modulr",
    path: "/import-data",
    icon: "file-import",
    dataPriv: "bo_viewparameters",
    group: groups.PARAMETERS.id,
  },
  {
    name: "Constantes",
    path: "/constants",
    icon: "balance-scale",
    dataPriv: "bo_viewparameters",
    group: groups.PARAMETERS.id,
  },

  //CONTENT
  {
    name: "Astuces PJ",
    path: "/hints",
    icon: "exclamation-circle",
    dataPriv: "bo_crudtestimonials",
    group: groups.CONTENT.id,
  },
  {
    name: "FAQ",
    path: "/faqs",
    icon: "question-circle",
    dataPriv: "bo_crudtestimonials",
    group: groups.CONTENT.id,
  },
  {
    name: "Pages dynamiques",
    path: "/dynamic-pages",
    icon: "file",
    dataPriv: "bo_modifypages",
    group: groups.CONTENT.id,
  },
  {
    name: "Témoignages",
    path: "/testimonials",
    icon: "bullhorn",
    dataPriv: "bo_crudtestimonials",
    group: groups.CONTENT.id,
  },
];
