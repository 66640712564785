import React, { useEffect, useState } from "react";
import Page from "../../common/layout/Page";
import Axios from "axios";
import { API_URL } from "../../../config";
import { Link } from "react-router-dom";

const DynamicPageList = () => {
  const [pages, setPages] = useState([]);

  useEffect(() => {
    Axios.post(API_URL + "pages/search", { page: {}, filters: {} }).then(
      (res) => {
        setPages(res.data.pages);
      }
    );
  }, []);

  return (
    <Page title="Pages dynamiques">
      <div className="row">
        <div className="col-12">
          <ul className="list-group w-100">
            {pages.map((p, pk) => (
              <li className="list-group-item d-flex align-items-center justify-content-between">
                <span>{p.name}</span>
                <Link to={"/dynamic-pages/" + p.id} className="btn btn-default">
                  <i className="fa fa-cog" />
                </Link>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </Page>
  );
};

export default DynamicPageList;
