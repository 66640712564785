import styled from "styled-components";

export const PanelSectionTitle = styled.h4`
  padding: 8px 0px;
  padding-top: 0px;
  margin: 0;
  color: var(--color-success);
  font-weight: 700;
  font-size: 15px;
  position: relative;
`;

export const PanelSectionTitleAbs = styled.h4`
  position: relative;
  z-index: 99;
  top: 3px;
  left: 13px;
  padding: 8px 0px;
  /* padding-top: 0px; */
  margin: 0;
  font-weight: 700;
  color: var(--color-success);
  font-size: 15px;
`;

export const EllipsisContent = styled.div`
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`;
