import { DateTime } from "luxon";
import tools from "../../../../../../../../../../helpers/tools";
import Loader from "../../../../../../../../../common/Loader";

const OlderDocs = ({
  document,
  onDownload,
  onRemove,
  isDownloadingDocId,
  isRemovingDocId,
  removeDataPriv = "",
  docMeta = {},
}) => {
  console.log("document", document);
  return (
    <ul className="h-100 list-group">
      {document.map((doc, docIndex) => {
        if (docIndex == 0) return null;

        let docExt = doc.fileName.split(".");
        docExt = docExt[docExt.length - 1];
        const fileName = tools.getDocumentName({
          fileExt: docExt,
          documentName: doc?.fileType?.name || "",
          productName: docMeta?.productName || "",
          companyName: docMeta?.companyName || "",
          uploadedAt: DateTime.fromISO(doc.uploadedAt).toFormat("yyyy"),
        });

        console.log("fileName", fileName);

        return (
          <li
            className="list-group-item p-1 d-flex align-items-center justify-content-between border-top-0 border-left-0 border-right-0 rounded-0 border-bottom"
            key={`olderdoc${doc.id}`}
          >
            <div>
              {fileName} {tools.formatDate(doc.uploadedAt)}
            </div>
            <div className="d-center ">
              <button
                className="btn btn-sm btn-link"
                onClick={() => onDownload(doc)}
              >
                {isDownloadingDocId == doc.id ? (
                  <Loader />
                ) : (
                  <i className="fa fa-download text-primary" />
                )}
              </button>
              <button
                className="btn btn-sm btn-link"
                onClick={() => onRemove(doc.id)}
                data-priv={removeDataPriv}
              >
                {isRemovingDocId == doc.id ? (
                  <Loader />
                ) : (
                  <i className="fa fa-times text-danger" />
                )}
              </button>
            </div>
          </li>
        );
      })}
    </ul>
  );
};

export default OlderDocs;
