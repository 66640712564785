import { DateTime } from "luxon";
import React, { useEffect } from "react";
import Page from "../../common/layout/Page";
import Loader from "../../common/Loader";
import useFtpClient from "./useFtpClient";

const FtpClient = () => {
  const { state, actions } = useFtpClient();
  console.log("STATE", state);
  return (
    <Page title="Recupérer le fichier BFC sur le FTP">
      {state.isConnecting ? (
        <Loader />
      ) : (
        <ul className="list-group">
          {state.files.map((file, fk) => (
            <li
              className="list-group-item d-flex justify-content-between align-items-center "
              key={`file${fk}`}
            >
              <div className="d-flex justify-content-start align-items-center">
                <i className="fa fa-file fa-2x mr-2" />

                {file.name}
              </div>
              <div className="d-center flex-row">
                <div className="d-flex justify-content-start align-items-start flex-column ml-2">
                  <span>
                    <i className="fa fa-calendar-alt mr-1" />
                    {DateTime.fromMillis(file.modifyTime)
                      .setLocale("fr")
                      .toFormat("dd LLLL yyyy HH'h'mm")}
                  </span>
                  <span>
                    <i className="fa fa-weight-hanging mr-1" />
                    {Math.round(file.size / 1000)} ko
                  </span>
                </div>
                <button
                  className="btn btn-sm btn-primary mx-1"
                  onClick={() => actions.downloadFile(file)}
                >
                  {state.isDownloading == file.name ? (
                    <Loader />
                  ) : (
                    <i className="fa fa-2x fa-download" />
                  )}
                </button>
                <button
                  className="btn btn-sm btn-danger mx-1"
                  onClick={() => actions.deleteFile(file)}
                >
                  {state.isDeleting == file.name ? (
                    <Loader />
                  ) : (
                    <i className="fa fa-2x fa-times" />
                  )}
                </button>
              </div>
            </li>
          ))}
        </ul>
      )}
    </Page>
  );
};

export default FtpClient;
