import React from "react";
import useCompanyItem from "../useCompanyItem";
import BPCompanyInfo from "./BPCompanyInfo/BPCompanyInfo";
import BPDocuments from "./BPDocuments/BPDocuments";
import BPPolicies from "./BPPolicies/BPPolicies";
import BPUsers from "./BPUsers/BPUsers";
import CompanyBottomPanel from "./CompanyBottomPanel/CompanyBottomPanel";
import BPBills from "./BPBills/BPBills";

const BottomPanel = () => {
  const { bottomPanelContent, actions, company, openedPanelName } =
    useCompanyItem();

  return !bottomPanelContent ? null : (
    <CompanyBottomPanel>
      <div className="mb-2">
        <h4 className="py-0 mb-0">
          <i className="fa fa-building"></i> {company.company}
        </h4>
        <small className="text-default">siret : {company.siret}</small>
      </div>
      <div className="d-flex flex-row flex-wrap mb-3">
        <button
          className={`btn btn-sm btn-light  ${
            openedPanelName === "COMPANY" ? " active" : ""
          }`}
          onClick={() => actions.openBottomPanel("COMPANY")}
          title="Informations détaillées"
        >
          <i className="fa fa-info-circle" /> Informations détaillées
        </button>

        <button
          className={`btn btn-sm btn-light  ${
            openedPanelName === "DOCUMENTS" ? " active" : ""
          }`}
          data-priv="bo_viewcompanydocs"
          onClick={() => actions.openBottomPanel("DOCUMENTS")}
          title="Documents"
        >
          <i className="fa fa-file"></i> Documents
        </button>

        {company.policies?.length > 0 && (
          <button
            className={`btn btn-sm btn-light  ${
              openedPanelName === "POLICIES" ? " active" : ""
            }`}
            onClick={() => actions.openBottomPanel("POLICIES")}
            title="Contrats"
          >
            <i className="fa fa-file-contract"></i> Contrats
          </button>
        )}

        <button
          className={`btn btn-sm btn-light  ${
            openedPanelName === "BILLS" ? " active" : ""
          }`}
          data-priv="bo_viewcontractdocs"
          onClick={() => actions.openBottomPanel("BILLS")}
          title="Factures"
        >
          <i className="fa fa-file-invoice-dollar"></i> Factures
        </button>

        <button
          className={`btn btn-sm btn-light  ${
            openedPanelName === "USERS" ? " active" : ""
          }`}
          onClick={() => actions.openBottomPanel("USERS")}
          title="Utilisateurs"
        >
          <i className="fa fa-users"></i> Utilisateurs
        </button>
      </div>
      {(() => {
        switch (bottomPanelContent) {
          case "COMPANY":
            return <BPCompanyInfo />;
          case "DOCUMENTS":
            return <BPDocuments docType="COMPANY" />;
          case "POLICIES":
            return <BPPolicies />;
          case "BILLS":
            return <BPBills />;
          case "USERS":
            return <BPUsers />;
          case "EVENTS":
            return <>évènements...</>;

          default:
            return null;
        }
      })()}
    </CompanyBottomPanel>
  );
};

export default BottomPanel;
