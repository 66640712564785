import React, { useState } from "react";
import styled from "styled-components";
import useStore from "../../../../context/useStore";
import useManagers from "../../../../hooks/useManagers/useManagers";
import useRole from "../../../../hooks/useRole/useRole";
import Control from "../../../common/Controls/Control";
import {
  filterContainerClassName,
  searchButtonClassName,
  searchButtonContainerClassName,
  searchButtonLoaderColor,
  searchButtonType,
} from "../../../common/Controls/filterContainer";
import SaveBtn from "../../../common/SaveBtn";
import useLeads from "../useLeads";
import LinkedLeadsFilters from "./LinkedLeadsFilters";
import ProducerSelect from "./ProviderSelect";
import useLeadFilters from "./useLeadFilters";
import { DateTime } from "luxon";

const LeadListFilters = () => {
  const { managers } = useManagers();
  const [state] = useStore();
  const { myRole } = useRole();
  const {
    QUOTATION_PRODUCT_TYPES,
    QUOTE_PRODUCT_TYPES,
    LEAD_STATUS,
    ROLES_PER_BO,
  } = state.constants.items;
  const { leadsFilters, actions, showExpiredManagers } = useLeadFilters();
  const {
    actions: leadActions,
    isSearching,
    showOnlyLinkedLeads,
    productCategory,
  } = useLeads();
  const {
    updateFilters,
    resetLeadsFilters,
    updateOneMonth,
    toggleShowExpiredManagers,
  } = actions;
  const { terms, products, assignedTo, statuses, beginAt, endAt } =
    leadsFilters.filters;
  const [showAdvancedSearch, setShowAdvancedSearch] = useState(false);

  const managersByRoles = [];
  ROLES_PER_BO.forEach((role) => {
    // add isExpired: true to managers whose contractEndAt is in the past

    managersByRoles.push({
      role: role.name,
      managers: managers
        .filter(
          (manager) =>
            manager.roles[0].roleKey === role.id &&
            (leadsFilters.filters.producer
              ? manager.producer &&
                leadsFilters.filters.producer == manager.producer
              : true)
        )
        .map((manager) => ({
          ...manager,
          isExpired: DateTime.fromISO(manager.contractEndAt) < DateTime.now(),
        })),
    });
  });

  const expiredManagersByRoles = managersByRoles.map((role) => ({
    ...role,
    managers: role.managers.filter((manager) => manager.isExpired),
  }));

  console.log("managersByRoles", managersByRoles);

  const onSubmitSearch = (e) => {
    e.preventDefault();
    leadActions.getLeads();
  };

  const commonControlProps = {
    noMarginOnFormGroup: true,
    noPaddingOnFormGroup: true,
    margin: "m-0",
    noBg: true,
  };

  const productListByCategory = {
    PRO: QUOTATION_PRODUCT_TYPES,
    DOMI: QUOTE_PRODUCT_TYPES,
  };

  const searchTermsLabelByCategory = {
    PRO: (
      <>
        <div>
          <strong>Recherche Société</strong>
        </div>{" "}
        <div>(société, siret ou ape)</div>
      </>
    ),
    DOMI: (
      <>
        <div>
          <strong>Rechercher un prospect</strong>
        </div>{" "}
        <div>(nom, prénom ou email)</div>
      </>
    ),
  };

  return showOnlyLinkedLeads ? (
    <div className="row mb-3 ">
      <div className="col-12">
        <LinkedLeadsFilters />
      </div>
    </div>
  ) : (
    <>
      <form onSubmit={onSubmitSearch}>
        <div className={filterContainerClassName}>
          <div className="col-12">
            <Control
              label={searchTermsLabelByCategory[productCategory]}
              type="text"
              name="terms"
              value={terms}
              change={updateFilters}
              fullWidth
              {...commonControlProps}
            />
          </div>
          {showAdvancedSearch && (
            <>
              <div className="col">
                <Control
                  label="Produits"
                  name="products"
                  type="checkBtnList"
                  value={products}
                  change={updateFilters}
                  datas={productListByCategory[productCategory].map((d) => ({
                    ...d,
                    label: d.id.toUpperCase(),
                  }))}
                  dataIndex="id"
                  dataLabel="label"
                  dataIcon="icon"
                  btnInline
                  {...commonControlProps}
                />
              </div>
              <div className="col">
                <Control
                  label="Statuts"
                  name="statuses"
                  type="checkBtnList"
                  value={statuses}
                  datas={LEAD_STATUS.map((l) => {
                    console.log(l);
                    return l.id === "IN_PROGRESS"
                      ? {
                          ...l,
                          name: "En cours",
                        }
                      : l;
                  })}
                  change={updateFilters}
                  dataIndex="id"
                  dataLabel="name"
                  dataIcon="icon"
                  btnInline
                  {...commonControlProps}
                />
              </div>

              <div className="col-12 mb-3">
                {myRole !== "PARTNER" && productCategory === "PRO" && (
                  <ProducerSelect commonControlProps={commonControlProps} />
                )}
                <div className="row">
                  {managersByRoles
                    .filter((role) => role.managers.length > 0)
                    .map((role) => {
                      const expiredUsers = expiredManagersByRoles.find(
                        (expiredRole) => expiredRole.role === role.role
                      );
                      return (
                        <div
                          className="col-12 col-md-2"
                          key={`btnrolecol${role.name}`}
                        >
                          <Control
                            label={role.role}
                            name="assignedTo"
                            type="checkBtnList"
                            value={assignedTo}
                            datas={role.managers
                              .filter((m) => !m.isExpired)
                              .sort((a, b) =>
                                a.fullName.localeCompare(b.fullName)
                              )}
                            change={updateFilters}
                            dataIndex="id"
                            dataLabel="fullName"
                            noBg
                            {...commonControlProps}
                          />
                          {/* display a button "+x autres" if there are expired managers for this role */}
                          {expiredUsers &&
                            expiredUsers?.managers?.length > 0 && (
                              <>
                                <button
                                  type="button"
                                  className="btn btn-link text-danger p-0"
                                  onClick={() =>
                                    toggleShowExpiredManagers(role.role)
                                  }
                                >
                                  +{expiredUsers.managers.length} expiré(s)
                                </button>
                                {showExpiredManagers && (
                                  <Control
                                    label=""
                                    name="assignedTo"
                                    type="checkBtnList"
                                    value={assignedTo}
                                    datas={
                                      expiredManagersByRoles.find(
                                        (expiredRole) =>
                                          expiredRole.role === role.role
                                      ).managers
                                    }
                                    change={updateFilters}
                                    dataIndex="id"
                                    dataLabel="fullName"
                                    noBg
                                    {...commonControlProps}
                                  />
                                )}
                              </>
                            )}
                        </div>
                      );
                    })}
                </div>
                {/* <Control
                  label="Assigné à"
                  name="assignedTo"
                  type="checkBtnList"
                  value={assignedTo}
                  datas={managers.filter((manager) =>
                    leadsFilters.filters.producer
                      ? manager.producer &&
                        leadsFilters.filters.producer == manager.producer
                      : true
                  )}
                  change={updateFilters}
                  dataIndex="id"
                  dataLabel="fullName"
                  btnInline
                  {...commonControlProps}
                /> */}
              </div>
              <div className="col-12 col-md-3">
                <Control
                  label="Du"
                  name="beginAt"
                  type="date"
                  value={beginAt}
                  change={updateFilters}
                  inputStyle={{ borderRadius: 5 }}
                  {...commonControlProps}
                />
              </div>
              <div className="col-12 col-md-3">
                <Control
                  label="Au"
                  name="endAt"
                  type="date"
                  value={endAt}
                  change={updateFilters}
                  inputStyle={{ borderRadius: 5 }}
                  {...commonControlProps}
                />
              </div>
              <div className="col-12 col-md-3 d-flex align-items-center">
                <button
                  className="w-95 d-center btn bg-white btn-sm"
                  onClick={updateOneMonth}
                >
                  30 derniers jours
                </button>
              </div>
            </>
          )}
          <div className={searchButtonContainerClassName}>
            <SaveBtn
              className={searchButtonClassName}
              typeBtn="submit"
              text="Rechercher"
              isSaving={isSearching}
              type={searchButtonType}
              loaderColor={searchButtonLoaderColor}
            />
            <button
              className="d-center btn btn-link text-dark ml-2"
              onClick={resetLeadsFilters}
            >
              Réinitialiser les filtres
            </button>
            {showAdvancedSearch ? (
              <button
                type="button"
                className="btn d-center btn-link"
                onClick={() => setShowAdvancedSearch(false)}
              >
                <i className="fa fa-cog mr-1" />
                Recherche simplifiée
              </button>
            ) : (
              <button
                className="btn d-center btn-link"
                type="button"
                onClick={() => setShowAdvancedSearch(true)}
              >
                <i className="fa fa-cog mr-1" />
                Recherche avancée
              </button>
            )}
          </div>
        </div>
      </form>
    </>
  );
};

const StyledLeadListFilters = styled.div``;

export default LeadListFilters;
